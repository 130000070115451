import React from 'react'
import { get, getStore, send } from 'mulan-lib'
import { Table, Row, Col, Input, Upload, Message, Button, Icon } from 'antd'
import createColumns from './columns'
import EditForm from './form'
import { processRequest } from 'lib'

const { Search } = Input


class Admin extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}

    const currentPub = getStore('currentPub', 'session')
    this.handleCancel = this.handleCancel.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleOperate = this.handleOperate.bind(this)
    this.handleRemoveFiles = this.handleRemoveFiles.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleFetchData = this.fetchDataSource.bind(this)
    this.saveFormRef  = this.saveFormRef.bind(this)
    this.handleAdd = this.handleAdd.bind(this)

    this.state = {
      columns: createColumns({
        onUpdate: this.handleUpdate,
        onDel: this.handleSingleDelete,
        onOperate: this.handleOperate,
        onRemoveFile: this.handleRemoveFiles,
        onRefresh: this.handleFetchData,
      }),
      filterStatus: 2,
      visible: false,
      adminKey,
      currentPub,
      from: 0,
      max: 999,
      selectedRowKeys: []
    }
  }
  handleUpdate(record) {
    this.setState({ visible: true, record: record });
    // ...
  }
  handleSelectChange(selectedRowKeys) {
    console.log('selectedRowKeys changed: ', selectedRowKeys)
    this.setState({ selectedRowKeys })
  }
  handleOperate(record, isDel) {
    const { _id, status } = record
    const { adminKey } = this.state
    const nextStatus = parseInt(status) + 1

    const params = {
      id: _id
    }
    if (isDel) {
      params.isInvoiceDel = true
    } else {
      params.status = nextStatus
    }
    processRequest(
      send('/api/admin/common/func', {
        adminKey,
        url: 'order/m/operate',
        data: params
      }),
      data => {
        Message.success('操作成功！')
        this.fetchDataSource()
      }
    )
  }
  handleRemoveFiles(record, idx) {
    let { _id, files } = record
    const { adminKey } = this.state
    files.splice(idx, 1)
    processRequest(
      send('/api/admin/common/func', {
        adminKey,
        url: 'order/m/operate',
        data: {
          files,
          notify: false,
          id: _id
        }
      }),
      data => {
        Message.success('操作成功！')
        this.fetchDataSource()
      }
    )
  }
  fetchDataSource(cb) {
    const { adminKey, from, max, filterStatus, filterOrder } = this.state
    console.log('fetch source')
    const postData = {
      invoiceStatus: filterStatus
    }
    if (!!filterOrder) {
      postData.orderId = filterOrder
    }
    processRequest(
      send('/api/admin/common/func', {
        adminKey,
        url: 'order/m/list',
        data: postData
      }),
      data => {
        // console.log(data)
        this.setState({
          dataSource: data.map(o => ({ ...o, key: o._id })),
          canFetch: false,
          loading: false
        })
      }
    )
  }
  static getDerivedStateFromProps(props, state) {
    const status = props.params.status
    if (status != state.filterStatus) {
      return {
        ...state,
        canFetch: true,
        list: [],
        dataSource: null,
        loading: true,
        filterStatus: parseInt(status)
      }
    }
    return null
  }
  componentDidUpdate() {
    const { canFetch } = this.state
    if (canFetch) this.fetchDataSource()
  }
  handleTimingPull() {
    setInterval(() => {
      this.fetchDataSource()
    }, 10000)
  }
  componentDidMount() {
    // console.log(this.props)
    const { params = {} } = this.props
    const { status = 1 } = params
    this.setState({
      filterStatus: parseInt(status),
      loading: true
    }, () => {
      // fetch 新数据
      this.fetchDataSource()
      // this.handleTimingPull()
    })

  }
  handleAdd() {
    this.setState({ visible: true })
  }
  handleCancel() {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false, record: {} })
  }
  handleCreate() {
    this.fetchDataSource()
    this.setState({ visible: false, record: {} })
  }
  saveFormRef(formRef) {
    this.formRef = formRef
  }
  handleFilterOrderChange(filterOrder) {
    this.setState({
          filterOrder
        }, this.fetchDataSource.bind(this))
  }
  render() {
    const { loading=true, dataSource, columns, isSearch = false, filterData = [], selectedRowKeys, record = {}  } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
    }
    return (
      <div ref='keyword'>
        <div style={{ marginBottom: '20px', textAlign: 'right' }}>
          <Row justify='space-between' type='flex'>
            <Col span={20}></Col>
            <Col span={4}>
              <Search placeholder='请输入检测ID号' onSearch={ this.handleFilterOrderChange.bind(this) } />
            </Col>

          </Row>
        </div>
        <Table loading={ loading } dataSource={ isSearch ? filterData : dataSource } columns={ columns }/>
      </div>
    )
  }
}

export default Admin
