import React from 'react'
import { Tag, Popconfirm, Upload, Select, Message, Popover, Row, Button, Col, Tooltip, InputNumber, Input } from 'antd'
import { getStore, get, send } from 'mulan-lib'
import { processRequest } from 'lib'
import { baseApiUrl } from 'config'
const Option = Select.Option

const props = (record, onRefresh, cb) => ({
  name: 'file',
  showUploadList: false,
  action: `${ baseApiUrl }/api/admin/common/upload/single`,
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
      Message.loading('上传中...', 0)
    }
    if (info.file.status === 'done') {


      // console.log(info.file.response)
      const { data } = info.file.response
      const { url } = data
      // console.log(url)
      cb(url, record)
      // onRefresh()
    } else if (info.file.status === 'error') {
      Message.destroy()
      Message.error(`${info.file.name} 上传失败`);
    }
  },
})

class App extends React.Component {
  constructor(props) {
    super(props)

    const { _id: recordId, record } = props
    const { cert, price } = record
    this.state = {
      recordId,
      record,
      cert,
      inputNo: price
    }
    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleConfirm() {
    const { recordId, inputNo = '', cert } = this.state
    if (inputNo == 0) {
      Message.warning('请输入有效价格')
      return
    }
    if (!!!cert) {
      Message.warning('请上传委托书')
      return
    }
    processRequest(
      send('/api/admin/common/func',
      {
        // adminKey,
        url: 'order/m/operate',
        data: {
          id: recordId,
          cert,
          price: parseFloat(inputNo.toFixed(2)),
        } }),
      data => {
        Message.success('操作成功')
        this.setState({
          visible: false
        }, this.props.onFetchSource)
      }
    )
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { record: nextRecord } = nextProps
    const { price: nextPrice, cert: nextCert } = nextRecord

    const { record: prevRecord } = prevState
    const { price: prevPrice, cert: prevCert } = prevRecord

    if ((nextPrice && nextCert) && (nextPrice !== prevPrice || nextCert !== prevCert)) {
      return {
        ...prevState,
        cert: nextCert,
        record: nextRecord,
      }
    }
    return null
  }
  componentDidMount() {}
  handleVisibleChange(e) {
    // console.log(e)
    if (e) {
      // this.fetchCategory()
    } else {
      this.setState({
        selectedCate: undefined,
        selectedType: undefined,
        selectedModelType: undefined,
        selectedBrand: undefined
      })
    }
    this.setState({
      visible: e
    })

  }
  handleUploadSuccess(url) {
    this.setState({
      cert: url,
    })
    Message.destroy()
    Message.success(`上传成功`)
  }

  render() {
    const { inputNo = 0, record, cert = '', price } = this.state
    // const { contactName } = this.props
    return (
      <Popover
          title="填写委托信息"
          trigger="click"
          visible={ this.state.visible }
          onVisibleChange={ this.handleVisibleChange }
          content={
            <div>
              <Upload style={{ width: '170px', display: 'block' }} {...props(record, null, this.handleUploadSuccess.bind(this))}>
                <Button style={{ width: '100%' }} type='normal'>{ !!cert ? '修改' : '选择' }委托单</Button>
              </Upload>
              <div style={{ margin: '.12rem 0' }}>
                <InputNumber placeholder='请输入检测价格' step={0.1} value={ inputNo }
                  onChange={ value => this.setState({ inputNo: value }) }
                  style={{ width: '100%' }} />
              </div>
              <div>
                <Button style={{ width: '100%' }} type='primary' onClick={ this.handleConfirm }>
                  { !!record.cert ? '修改' : '上传'  }
                </Button>
              </div>
            </div>
          }
          defaultVisible={ false }
        >
          <a style={{ fontSize: '.14rem', marginLeft: '.12rem' }}>{ !!record.cert ? '修改' : '上传'  }委托信息</a>
        </Popover>
      )
  }
}
export default App
