const menuList = [
  {
    name: '检测中心', children: [
      {
        name: '需求待沟通', url: '/admin/order/list/1',
      }, {
        name: '需求待检测', url: '/admin/order/list/2',
      }, {
        name: '需求检测中', url: '/admin/order/list/3',
      }, {
        name: '需求已完成', url: '/admin/order/list/4',
      }, {
        name: '需求已放弃', url: '/admin/order/list/-1',
      },
    ],
  },
  {
    name: '发票中心', children: [
      {
        name: '待开票', url: '/admin/invoice/2',
      }, {
        name: '已开票', url: '/admin/invoice/3',
      }, {
        name: '已放弃', url: '/admin/invoice/4',
      },
    ],
  },
  {
    name: '退款中心', children: [
      {
        name: '待退款', url: '/admin/refund/1',
      }, {
        name: '已退款', url: '/admin/refund/2',
      }
    ],
  },
]

export const getCurrentBread = defaultKey => {
  const reply = {}
  menuList.forEach(({ name, url, children = [] }) => {
    if (children.length > 0) {
      children.forEach(({ url, name: name2 }) => {
        if (defaultKey === url) {
          reply.breadName = `${ name } / ${ name2 }`
          reply.pageName = name2
        }
      })
    } else {
      if (defaultKey === url) {
        reply.breadName = name
        reply.pageName = name
      }
    }
  })
  return reply
}

export default menuList
