import React from 'react'
import { Modal, Form, Input, Select, Message, InputNumber, DatePicker } from 'antd'
import { getStore, send, setStore } from 'mulan-lib'
import { processRequest } from 'lib'
import html2canvas from 'html2canvas'
const { Option } = Select
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 },
  },
  style: {
    width: '250px'
  },
  colon: true
};
const formItemLayout2 = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  style: {
    width: '265px'
  },
  colon: true
};
const { TextArea } = Input
const FormBlock = props => {
  const { children, title, extra } = props
  return (
    <div
      className='form-block'
      style={{ marginTop: '12px', borderRadius: '5px', boxSizing: 'border-box', overflow: 'hidden', border: '1px solid #e5e5e6' }}>
      <div style={{ backgroundColor: '#f5f5f6', padding: '3px 12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>{ title }</div>
        { extra }
      </div>
      <div style={{ padding: '12px', boxSizing: 'border-box' }}>{ children }</div>
    </div>
  )
}
class StoreForm extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}

    this.state = {
      adminKey,
      testInfo: [],
      record: {
        testInfo: [{}]
      },
    }
  }
  componentWillUnmount() {
    const { form } = this.props
    form.resetFields()
  }
  componentDidMount() {
    const { order = {} } = this.props
    // console.log(record)
    const { weituo, price } = order
    console.log(order,weituo)
    if (!!weituo) {
      this.setState({
        record: {
          ...weituo,
          price
        }
      })
    }
  }

  handleDownLoad() {
    const { onCreate, order, viewForm } = this.props
    this.screenshots()
    // if (viewForm) {
    //   this.setState({
    //     visible: false
    //   }, this.props.onCreate)
    //   return
    // }
  }
  handleCreate() {
    const { onCreate, order, viewForm } = this.props

    const { adminKey } = this.state

    const form = this.props.form

    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { price, ...others } = values
      if (!(price > 0)) {
        Message.error('请填写有效的检测金额')
        return
      }
      console.log(values)
      const { _id: recordId } = order

      processRequest(
        send('/api/admin/common/func',
        {
          // adminKey,
          url: 'order/m/operate',
          data: {
            id: recordId,
            weituo: others,
            price: parseFloat(price.toFixed(2)),
          } }),
        data => {
          Message.success('操作成功')
          setStore('weituo', '', 'session')
          this.setState({
            visible: false
          }, this.props.onCreate)
        }
      )
      // let path = '/api/admin/account/create'
      //
      // const { _id, ...params } = values
      //
      // const postData = {
      //   ...params,
      //   adminKey
      // }
      // if (_id) {
      //   postData._id = _id
      //   path = '/api/admin/account/operate'
      // }
      // processRequest(
      //   send(path, postData),
      //   data => {
      //     console.log(data)
      //     form.resetFields()
      //     onCreate()
      //   }
      // )
    })
  }
  handleAdd() {
    const { record } = this.state
    const { testInfo } = record
    record.testInfo = [...testInfo, {}]
    this.setState({
      record
    })
  }
  handleRemove(i) {
    const { record } = this.state
    const { testInfo } = record
    testInfo.splice(i, 1)
    record.testInfo = testInfo
    this.setState({
      record
    })
  }
  screenshots(){
    const dom = window.document.querySelector('.ant-modal-body')//this.refs.weituoForm //document.getElementById("weituo-form")
    // console.log(dom)
    // return
    const { order } = this.props
    const { orderId } = order
    let url = this.getPdfImg(dom, url => {
      console.log(url)
      this.down(url, `检测委托单-${ orderId }`)
    })
  }
  getPdfImg(dom, cb) {
    html2canvas(dom, {
      useCORS: true,
      scale: 2,
      dpi: window.devicePixelRatio,
    }).then(canvas => {
      console.log(canvas)
      const url = canvas.toDataURL('image/png', 1.0)
      cb(url)
    })

    // html2canvas(dom, {
    //     dpi: 300,
    //     useCORS: true, // 跨域设置-- img 增加 crossorigin="anonymous" 属性
    //     onrendered: function(image) {
    //       console.log(image)
    //         let url = image.toDataURL('image/png', 1.0); // 生成base64图片
    //         resolve(url);// 返回url
    //     },
    // })
    // return new Promise((resolve, reiect) => {
    //     html2canvas(dom, {
    //         dpi: 300,
    //         useCORS: true, // 跨域设置-- img 增加 crossorigin="anonymous" 属性
    //         onrendered: function(image) {
    //           console.log(image)
    //             let url = image.toDataURL('image/png', 1.0); // 生成base64图片
    //             resolve(url);// 返回url
    //         },
    //     })
    //
    // })
  }
  // 下载功能
  down(url, title) {
    const a = document.createElement('a');
    a.download = title
    a.setAttribute('href', url); // 下载
    a.click();
    a.remove();
  }
  handleCancel() {
    const { onCancel, form, order } = this.props

    form.validateFields((err, values) => {
      // if (err) {
      //   onCancel()
      //   return;
      // }
      const { price, ...others } = values
      if (!(order.price > 0)) {
        setStore('weituo', others, 'session')
      }
      onCancel()
    })

  }
  render() {
    const {
        visible, onCancel, onCreate, form,
        // record = {}
        viewForm,
        order
    } = this.props
    const { record } = this.state
    const { weituo } = order

    const { getFieldDecorator, getFieldValue } = form
    let lingqufangshiInput = getFieldValue('lingqufangshi')
    return (
      <Modal
          ref='weituoForm'
          visible={ visible }
          title={ `${ viewForm ? '查看' : !!weituo ? '修改' : '添加' }委托单`  }
          okText= { viewForm ? '下载委托单' : '确定' }
          cancelText= '取消'
          onCancel={ this.handleCancel.bind(this) }
          maskClosable={ false }
          onOk={ viewForm ? this.handleDownLoad.bind(this) : this.handleCreate.bind(this) }
          style={{ width: '1000px' }}
        >
          <Form layout="inline">

            <Form.Item label="检测ID" { ...formItemLayout2 } >
              <span style={{ fontSize: '14px' }}>{ order.orderId }</span>
            </Form.Item>
            <Form.Item label="检测金额" { ...formItemLayout }>
              {
                viewForm ? `￥${ record.price }` :
                getFieldDecorator('price', {
                initialValue: record.price || 0,
                rules: [{ required: true, message: '请输入检测金额!' }],
              })(

                <InputNumber placeholder='请输入检测价格' step={1} />
              )}
            </Form.Item>

            <FormBlock title='委托单位信息'>
              <Form.Item label="单位名称" { ...formItemLayout }>
                {
                  viewForm ? `${ record.companyName }` :
                  getFieldDecorator('companyName', {
                  initialValue: record.companyName || '',
                  rules: [{ required: true, message: '请输入单位名称!' }],
                })(
                  <Input placeholder='请输入单位名称' />
                )}
              </Form.Item>

              <Form.Item label="联系人" { ...formItemLayout }>
                {
                  viewForm ? `${ record.contactName }` :
                  getFieldDecorator('contactName', {
                  initialValue: record.contactName || '',
                  rules: [{ required: true, message: '请输入联系人!' }],
                })(
                  <Input placeholder='请输入联系人' />
                )}
              </Form.Item>

              <Form.Item label="联系电话" { ...formItemLayout }>
                {
                  viewForm ? `${ record.contactPhone }` :
                  getFieldDecorator('contactPhone', {
                  initialValue: record.contactPhone || '',
                  rules: [{ required: true, message: '请输入联系电话!' }],
                })(
                  <Input placeholder='请输入联系电话' />
                )}
              </Form.Item>

              <Form.Item label="单位地址" style={ viewForm ? {marginLeft:'24px',} : { marginLeft:'12px', width: '790px', marginRight: 0 }} >
                {
                  viewForm ? `${ record.companyAddress }` :
                  getFieldDecorator('companyAddress', {
                  initialValue: record.companyAddress || '',
                  rules: [{ required: true, message: '请输入单位地址!' }],
                })(
                  <Input placeholder='请输入单位地址' style={{ width: '688px' }} />
                )}
              </Form.Item>
            </FormBlock>
            {
              record.testInfo.map((o, i) => {
                return (
                  <FormBlock key={ `yang-test-${ i }` } title='样品测试信息' extra={
                      viewForm ? '' : (
                        i === 0 ? (
                          <a href='javascript:void(0)' onClick={ this.handleAdd.bind(this) }>新增信息</a>
                        ) : (
                          <a href='javascript:void(0)' onClick={ this.handleRemove.bind(this, i) }>删除信息</a>
                        )
                      )
                    }>
                    <FormBlock title='样品信息'>
                      <Form.Item label="样品名称" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.yangName }` :
                          getFieldDecorator(`testInfo[${ i }].yangName`, {
                          initialValue: o.yangName || '',
                          rules: [{ required: true, message: '请输入样品名称!' }],
                        })(
                          <Input placeholder='请输入样品名称' />
                        )}
                      </Form.Item>

                      <Form.Item label="样品商标" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.shangbiao }` :
                          getFieldDecorator(`testInfo[${ i }].shangbiao`, {
                          initialValue: o.shangbiao || '',
                          rules: [{ required: true, message: '请输入商标!' }],
                        })(
                          <Input placeholder='请输入商标' />
                        )}
                      </Form.Item>

                      <Form.Item label="规格型号" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.guige }` :
                          getFieldDecorator(`testInfo[${ i }].guige`, {
                          initialValue: o.guige || '',
                          rules: [{ required: true, message: '请输入规格型号!' }],
                        })(
                          <Input placeholder='请输入规格型号' />
                        )}
                      </Form.Item>

                      <Form.Item label="样品数量" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.yangCount }` :
                          getFieldDecorator(`testInfo[${ i }].yangCount`, {
                          initialValue: o.yangCount || '',
                          rules: [{ required: true, message: '请输入样品数量!' }],
                        })(
                          <Input placeholder='请输入样品数量' />
                        )}
                      </Form.Item>

                      <Form.Item label="产品批号" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.productNo }` :
                          getFieldDecorator(`testInfo[${ i }].productNo`, {
                          initialValue: o.productNo || '',
                          rules: [{ required: true, message: '请输入产品批号!' }],
                        })(
                          <Input placeholder='请输入产品批号' />
                        )}
                      </Form.Item>

                      <Form.Item label="生产日期" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.createdDate }` :
                          getFieldDecorator(`testInfo[${ i }].createdDate`, {
                          initialValue: o.createdDate || '',
                          rules: [{ required: true, message: '请输入生产日期!' }],
                        })(
                          <Input placeholder='请输入生产日期' />
                        )}
                      </Form.Item>

                      <Form.Item label="质量等级" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.level }` :
                          getFieldDecorator(`testInfo[${ i }].level`, {
                          initialValue: o.level || '',
                          rules: [{ required: true, message: '请输入质量等级!' }],
                        })(
                          <Input placeholder='请输入质量等级' />
                        )}
                      </Form.Item>

                      <Form.Item label="生产工艺" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.gongyi }` :
                          getFieldDecorator(`testInfo[${ i }].gongyi`, {
                          initialValue: o.gongyi || '',
                          rules: [{ required: true, message: '请输入生产工艺!' }],
                        })(
                          <Input placeholder='请输入生产工艺' />
                        )}
                      </Form.Item>

                      <Form.Item label="产品类型" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.chanpingleixing }` :
                          getFieldDecorator(`testInfo[${ i }].chanpingleixing`, {
                          initialValue: o.chanpingleixing || '',
                          rules: [{ required: true, message: '请输入产品类型!' }],
                        })(
                          <Input placeholder='请输入产品类型' />
                        )}
                      </Form.Item>

                      <Form.Item label="样品状态"  style={ viewForm ? {marginLeft:'24px', width: '225px'} : { marginLeft:'14px', width: '500px' }}>
                        {
                          viewForm ? `${ o.zhuangtai1 || '' }` :
                          getFieldDecorator(`testInfo[${ i }].zhuangtai1`, {
                          initialValue: o.zhuangtai1 || '常温',
                          rules: [{ required: true, message: '请输入样品状态!' }],
                        })(
                          <Select style={{ width: '132px', }}>
                            {
                              ['常温', '冷藏', '冷冻', '其他'].map(o => {
                                return (
                                  <Option key={ `zhuangtai-${ o }` } value={ o }>{ o }</Option>
                                )
                              })
                            }
                          </Select>
                        )}
                        {
                          viewForm ? ` ${ o.zhuangtai2 || '' }` :
                          getFieldDecorator(`testInfo[${ i }].zhuangtai2`, {
                          initialValue: o.zhuangtai2 || '固体',
                          rules: [{ required: true, message: '请输入样品状态!' }],
                        })(
                          <Select style={{ width: '132px', marginLeft: '12px' }}>
                            {
                              ['固体', '液体', '半固体', '其他'].map(o => {
                                return (
                                  <Option key={ `zhuangtai-${ o }` } value={ o }>{ o }</Option>
                                )
                              })
                            }
                          </Select>
                        )}
                        {
                          viewForm ? ` ${ o.zhuangtai3 || '' }` :
                          getFieldDecorator(`testInfo[${ i }].zhuangtai3`, {
                          initialValue: o.zhuangtai3 || '包装完好',
                          rules: [{ required: true, message: '请输入样品状态!' }],
                        })(
                          <Select style={{ width: '132px', marginLeft: '12px' }}>
                            {
                              ['包装完好', '包装破损', '散装', '其他'].map(o => {
                                return (
                                  <Option key={ `zhuangtai-${ o }` } value={ o }>{ o }</Option>
                                )
                              })
                            }
                          </Select>
                        )}
                      </Form.Item>
                      <Form.Item label="储存要求" { ...formItemLayout } style={{ width: '250px' }}>
                        {
                          viewForm ? `${ o.chucunyaoqiu }` :
                          getFieldDecorator(`testInfo[${ i }].chucunyaoqiu`, {
                          initialValue: o.chucunyaoqiu || '常温',
                          rules: [{ required: true, message: '请输入储存要求!' }],
                        })(
                          <Select style={{ width: '155px', }}>
                            {
                              ['常温', '冷藏', '冷冻', '其他'].map(o => {
                                return (
                                  <Option key={ `chucun-${ o }` } value={ o }>{ o }</Option>
                                )
                              })
                            }
                          </Select>
                        )}
                      </Form.Item>

                      <Form.Item label="生产企业" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.qiye }` :
                          getFieldDecorator(`testInfo[${ i }].qiye`, {
                          initialValue: o.qiye || '',
                          rules: [{ required: true, message: '请输入生产企业名称!' }],
                        })(
                          <Input placeholder='请输入生产企业名称' />
                        )}
                      </Form.Item>
                      <Form.Item label="生产地址" style={ viewForm ? {marginLeft:'24px', width: '520px' } : { marginLeft:'12px', width: '520px' }}>
                        {
                          viewForm ? `${ o.address }` :
                          getFieldDecorator(`testInfo[${ i }].address`, {
                          initialValue: o.address || '',
                          rules: [{ required: true, message: '请输入生产生产地址!' }],
                        })(
                          <Input placeholder='请输入生产生产地址' style={{ width: '420px' }} />
                        )}
                      </Form.Item>
                    </FormBlock>

                    <FormBlock title='检测要求'>
                      <Form.Item label="检测依据" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.yiju }` :
                          getFieldDecorator(`testInfo[${ i }].yiju`, {
                          initialValue: o.yiju || '',
                          rules: [{ required: true, message: '请输入检测依据!' }],
                        })(
                          <Input placeholder='请输入检测依据' />
                        )}
                      </Form.Item>

                      <Form.Item label="结果要求" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.yaoqiu }` :
                          getFieldDecorator(`testInfo[${ i }].yaoqiu`, {
                          initialValue: o.yaoqiu || '出具判定报告',
                          rules: [{ required: true, message: '请输入联系人!' }],
                        })(
                          <Select style={{ width: '155px', }}>
                            {
                              ['出具数值结果', '出具判定报告'].map(o => {
                                return (
                                  <Option key={ `yaoqiu-${ o }` } value={ o }>{ o }</Option>
                                )
                              })
                            }
                          </Select>
                        )}
                      </Form.Item>

                      <Form.Item label="服务类型" { ...formItemLayout }>
                        {
                          viewForm ? `${ o.leixing }` :
                          getFieldDecorator(`testInfo[${ i }].leixing`, {
                          initialValue: o.leixing || '标准',
                          rules: [{ required: true, message: '请输入服务类型!' }],
                        })(
                          <Select style={{ width: '155px', }}>
                            {
                              ['标准','加急','特急','其他'].map(o => {
                                return (
                                  <Option key={ `leixing-${ o }` } value={ o }>{ o }</Option>
                                )
                              })
                            }
                          </Select>
                        )}
                      </Form.Item>

                      <Form.Item label="检测项目"  style={ viewForm ? {marginLeft:'24px' } : { marginLeft:'12px', width: '790px', marginRight: 0 }}>
                        {
                          viewForm ? <div style={{ wordBreak: 'break-all' }}>{ `${ o.xiangmu }` }</div> :
                          getFieldDecorator(`testInfo[${ i }].xiangmu`, {
                          initialValue: o.xiangmu || '',
                          rules: [{ required: true, message: '请输入检测项目!' }],
                        })(
                          <TextArea placeholder='请输入检测项目' style={{ marginTop: '6px', width: '688px', minHeight:'150px' }} />
                        )}
                      </Form.Item>
                    </FormBlock>

                  </FormBlock>

                )
              })
            }


            <FormBlock title='报告要求'>
              <Form.Item label="报告形式" { ...formItemLayout }>
                {
                  viewForm ? `${ record.reportFormat }` :
                  getFieldDecorator('reportFormat', {
                  initialValue: record.reportFormat || '中文报告',
                  rules: [{ required: true, message: '请输入报告形式!' }],
                })(
                  <Select style={{ width: '155px', }}>
                    {
                      ['中文报告', '英文报告', '中英文报告'].map(o => {
                        return (
                          <Option key={ `format-${ o }` } value={ o }>{ o }</Option>
                        )
                      })
                    }
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="领取方式" { ...formItemLayout }>
                {
                  viewForm ? `${ record.lingqufangshi }` :
                  getFieldDecorator('lingqufangshi', {
                  initialValue: record.lingqufangshi || '邮寄',
                  rules: [{ required: true, message: '请输入报告领取方式!' }],
                })(
                  <Select style={{ width: '155px', }}>
                    {
                      ['自取', '邮寄'].map(o => {
                        return (
                          <Option key={ `fangshi-${ o }` } value={ o }>{ o }</Option>
                        )
                      })
                    }
                  </Select>
                )}
              </Form.Item>
              {
                (lingqufangshiInput === '邮寄' || (viewForm && !!record.receiverContactName)) && (
                  <Form.Item label="联系人" { ...formItemLayout }>
                    {
                      viewForm ? `${ record.receiverContactName }` :
                      getFieldDecorator('receiverContactName', {
                      initialValue: record.receiverContactName || '',
                      rules: [{ required: true, message: '请输入联系人!' }],
                    })(
                      <Input placeholder='请输入联系人' />
                    )}
                  </Form.Item>
                )
              }
              {
                (lingqufangshiInput === '邮寄' || (viewForm && !!record.receiverContactPhone)) && (
                  <Form.Item label="联系电话" { ...formItemLayout }>
                    {
                      viewForm ? `${ record.receiverContactPhone }` :
                      getFieldDecorator('receiverContactPhone', {
                      initialValue: record.receiverContactPhone || '',
                      rules: [{ required: true, message: '请输入联系电话!' }],
                    })(
                      <Input placeholder='请输入联系电话' />
                    )}
                  </Form.Item>
                )
              }
              {
                (lingqufangshiInput === '邮寄' || (viewForm && !!record.receiverContactAddress)) && (
                  <Form.Item label="联系地址"  style={ viewForm ? {marginLeft:'24px', width: '520px' } : { marginLeft:'12px', width: '520px' }}>
                    {
                      viewForm ? `${ record.receiverContactAddress }` :
                      getFieldDecorator('receiverContactAddress', {
                      initialValue: record.receiverContactAddress || '',
                      rules: [{ required: true, message: '请输入联系地址!' }],
                    })(
                      <Input placeholder='请输入联系地址' style={{ width: '420px' }} />
                    )}
                  </Form.Item>
                )
              }
              <br />
              <Form.Item label="备注信息" style={{ marginLeft:'22px' }}>
                {
                  viewForm ? <div style={{ wordBreak: 'break-all' }}>{ `${ record.reportRemark }` }</div> :
                  getFieldDecorator('reportRemark', {
                  initialValue: record.reportRemark || '',
                })(
                  <TextArea placeholder='请输入备注信息' style={{ marginTop: '6px', width: '688px', minHeight:'150px' }}  />
                )}
              </Form.Item>
            </FormBlock>

          </Form>
        </Modal>
    )
  }
}

export default Form.create()(StoreForm)
