import React from 'react'
import { Tag, Popconfirm, Message } from 'antd'
import { baseApiUrl } from 'config'
import { moment } from 'mulan-lib'
import { processRequest, createColumn } from 'lib'

const cancel = (e) => console.log(e)

export default ({ onUpdate, onDel, onOperate }) => ([
  // createColumn('ID', '_id'),
  createColumn('名称', 'name'),
  createColumn('账号', 'account'),
  createColumn('创建时间', 'createdTime', (t, { createdTime }) => {
    return moment('YYYY-MM-DD HH:mm')(createdTime)
  }),
  createColumn('状态', 'status', (t, { status }) => {
    return status === 'Y' ? '启用' : '禁用'
  }),
  createColumn('', 'action',(t, record) => (
      <span>
        <Popconfirm title="是否确认操作?" onConfirm={ () => onOperate(record) } onCancel={ cancel } okText="是" cancelText="否">
          <a href="#" style={{ marginRight: '.12rem' }}>{ record.status === 'N' ? '启用' : '禁用' }</a>
        </Popconfirm>
        <a href="#" onClick={ () => onUpdate(record) }>编辑</a>

      </span>
  ))
])
