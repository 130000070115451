import React from 'react'
import { browserHistory } from 'react-router'
import { Menu, Icon } from 'antd'

const { SubMenu } = Menu

export default (
  (menuList, adminType, roleName) => {

    return menuList.filter(({ visible }) => visible !== false).map(o => {
      const { name, children = [], icon, type, role } = o

      if (type && type.indexOf(adminType) < 0 || (role && role !== roleName)) return false
      const createItem = ({ name, url, type, icon, hide, role }) => {
        if (((type && type.indexOf(adminType) < 0) || hide) || (role && role !== roleName)) return false
        return (
          <Menu.Item key={ url } onClick={ () => {
            if (!url) return
            if (url.indexOf('http') >= 0) {
              window.open(url)
            } else {
              browserHistory.push(url)
            }
          } }>
            <span>
              { icon && <Icon type={ icon } /> }
              <span>{ name }</span>
            </span>
          </Menu.Item>
        )
      }

      if (children.length > 0) {
        return (
          <SubMenu key={ name } title={
            <span>
              { icon && <Icon type={ icon } /> }
              <span>{ name }</span>
            </span>
          }>
            {
              children.filter(({ visible }) => visible !== false).map(o2 => createItem(o2))
            }
          </SubMenu>
        )
      } else {
        return createItem(o)
      }
    })
  }
)
