const projectName = 'ttlcw'

export const domain = `${ projectName }.adleading.com`

export const apiDomain = 'admin.moer.vip'
// export const apiDomain = 'localhost:8080'

export const baseUrl = `//${ domain }`

export const baseApiUrl = `//${ apiDomain }`

export const appid = 'wxf933b5ae6494a07b'
// export const appid = 'wx307d043f09838876'
// 开发者ID(AppID)   wx307d043f09838876
// Appsecret 9e0fa93141192c03967bb2c4cca12fd2
