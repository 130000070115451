import React from 'react'
import { Tag, Popconfirm,Select, Message, Popover, Row, Button, Col, Tooltip, InputNumber, Input } from 'antd'
import { getStore, get, send } from 'mulan-lib'
import { processRequest } from 'lib'

const Option = Select.Option
class App extends React.Component {
  constructor(props) {
    super(props)

    const { _id: recordId } = props
    this.state = {
      recordId
    }
    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleConfirm() {
    const { recordId, inputNo = '' } = this.state

    processRequest(
      send('/api/admin/common/func',
      {
        // adminKey,
        url: 'order/m/operate',
        data: {
          id: recordId,
          code: inputNo,
          status: 3,
        } }),
      data => {
        Message.success('修改成功')
        this.setState({
          visible: false
        }, this.props.onFetchSource)
        // console.log(data)
        // this.setState({
        //   dataSource: data.map(o => ({ ...o, key: o._id })),
        //   canFetch: false,
        //   loading: false
        // })
      }
    )
    // console.log(recordId, selectedCate, selectedType, selectedBrand, selectedModelType)
    // processRequest(
    //   get('/api/admin/doExpress', {
    //     id: recordId,
    //     inputNo
    //   }),
    //   data => {
    //     this.setState({
    //       visible: false
    //     }, this.props.onFetchSource)
    //   }
    // )
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    console.log(nextProps, prevState)
    return null
  }
  componentDidMount() {}
  handleVisibleChange(e) {
    // console.log(e)
    if (e) {
      // this.fetchCategory()
    } else {
      this.setState({
        selectedCate: undefined,
        selectedType: undefined,
        selectedModelType: undefined,
        selectedBrand: undefined
      })
    }
    this.setState({
      visible: e
    })

  }
  render() {
    const { inputNo } = this.state
    // const { contactName } = this.props
    return (
      <Popover
          title="填写样品号"
          trigger="click"
          visible={ this.state.visible }
          onVisibleChange={ this.handleVisibleChange }
          content={
            <div>
              <div style={{ margin: '.12rem 0' }}>
                <Input placeholder='请输入样品号' value={ inputNo } onChange={ ({ currentTarget }) => this.setState({ inputNo: currentTarget.value }) } style={{ width: '100%' }} />
              </div>
              <div>
                <Button style={{ width: '100%' }} type='primary' onClick={ this.handleConfirm }>确认</Button>
              </div>
            </div>
          }
          defaultVisible={ false }
        >
          <a>填写样品号</a>
        </Popover>
      )
  }
}
export default App
