import React from 'react'
import { get, getStore, send } from 'mulan-lib'
import { Table, Row, Col, Input, Upload, Message, Button, Icon } from 'antd'
import createColumns from './columns'
import EditForm from './form'
import { processRequest } from 'lib'

const { Search } = Input


class Admin extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}

    const currentPub = getStore('currentPub', 'session')
    this.handleCancel = this.handleCancel.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleOperate = this.handleOperate.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.saveFormRef  = this.saveFormRef.bind(this)
    this.handleAdd = this.handleAdd.bind(this)

    this.state = {
      columns: createColumns({
        onUpdate: this.handleUpdate,
        onDel: this.handleSingleDelete,
        onOperate: this.handleOperate,
      }),
      visible: false,
      adminKey,
      currentPub,
      from: 0,
      max: 999,
      selectedRowKeys: []
    }
  }
  handleUpdate(record) {
    this.setState({ visible: true, record: record });
    // ...
  }
  handleSelectChange(selectedRowKeys) {
    console.log('selectedRowKeys changed: ', selectedRowKeys)
    this.setState({ selectedRowKeys })
  }
  handleOperate(record, isDel) {
    const { _id, status } = record
    const { adminKey } = this.state
    processRequest(
      send('/api/admin/account/operate', {
        _id,
        status: isDel ? 'D' : (status === 'N' ? 'Y' : 'N'),
        adminKey
      }),
      data => {
        this.fetchDataSource()
      }
    )
  }
  fetchDataSource(cb) {
    const { adminKey, from, max } = this.state
    console.log('fetch source')
    processRequest(
      get('/api/admin/account', { adminKey, from, max }),
      data => {
        this.setState({ dataSource: data.map(o => ({ ...o, key: o._id })) })
      }
    )
  }
  componentDidMount() {
    // fetch 新数据
    this.fetchDataSource()
  }
  handleAdd() {
    this.setState({ visible: true })
  }
  handleCancel() {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false, record: {} })
  }
  // fetchDelete(recordIds) {
  //   get('/api/merchant/delMerchant', { recordIds }).then(({ code, data: replyData }) => {
  //     if (code === '9999') {
  //       message.success(`删除成功`)
  //       this.fetchDataSource(() => this.handleSearch(this.state.keyword))
  //     }
  //   })
  // }

  // handleSingleDelete(record) {
  //   this.fetchDelete(`${ record.recordId }`)
  //   // 删除
  // }
  handleCreate() {
    this.fetchDataSource()
    this.setState({ visible: false, record: {} })
  }
  saveFormRef(formRef) {
    this.formRef = formRef
  }
  render() {
    const { dataSource, columns, isSearch = false, filterData = [], selectedRowKeys, record = {}  } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
    }
    return (
      <div ref='keyword'>
        <div style={{ marginBottom: '20px', textAlign: 'right' }}>
          <Row justify='space-between' type='flex'>
              <Button type='primary' style={{ marginRight: '12px' }} onClick={ this.handleAdd }>
                添加账号
              </Button>
          </Row>
        </div>
        <Table dataSource={ isSearch ? filterData : dataSource } columns={ columns } />
        {
          this.state.visible && (
            <EditForm
              wrappedComponentRef={this.saveFormRef}
              record={ record }
              visible={ this.state.visible }
              onCancel={ this.handleCancel}
              onCreate={ this.handleCreate }
            />
          )
        }

      </div>
    )
  }
}

export default Admin
