import React from 'react'
import { Tag, Popconfirm,Select, Message, Popover, Row, Button, Col, Tooltip, InputNumber, Input } from 'antd'
import { getStore, get, send } from 'mulan-lib'
import { processRequest } from 'lib'

// const cancel = (e) => console.log(e)

class App extends React.Component {
  constructor(props) {
    super(props)

    const { _id: recordId } = props
    this.state = {
      recordId
    }
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleConfirm() {
    // const { record, }
    const { recordId } = this.state

    processRequest(
      send('/api/admin/common/func',
      {
        // adminKey,
        url: 'order/m/refund',
        data: {
          id: recordId,
        } }),
      data => {
        console.log(data)
        Message.success('退款成功')
        this.setState({
          visible: false
        }, this.props.onFetchSource)
      }
    )
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps, prevState)
    return null
  }
  handleCancel(e) {
    console.log(e)
  }
  componentDidMount() {}
  render() {
    const { record } = this.props
    const { payWay = '' } = record

    // const { contactName } = this.props
    return (
      <Popconfirm title={ `是否确认${ payWay === 'tickets' ? '已' : '' }退款？` }
        onConfirm={ this.handleConfirm.bind(this) }
        onCancel={ this.handleCancel.bind(this) } okText="确认" cancelText="取消">
        <a style={{ fontSize: '.14rem', marginLeft: '.12rem' }}>
          { `确认${ payWay === 'tickets' ? '已' : '' }退款` }
        </a>
      </Popconfirm>
    )
  }
}
export default App
