import React from 'react'
import { Tag, Popconfirm, Message, Upload, Icon } from 'antd'
import { baseApiUrl } from 'config'
import { moment, send, getStore } from 'mulan-lib'
import { processRequest, createColumn } from 'lib'
import Clipbord from 'component/copyToClipbord'
import InputNo from './input-no'
import InputPrice from './input-price'
import ConfirmTickets from './confirm-tickets'
import CheckRefund from './check-refund'
import ViewTicket from './view-ticket'
const cancel = (e) => console.log(e)

const props = (record, onRefresh, cb) => ({
  name: 'file',
  showUploadList: false,
  action: `${ baseApiUrl }/api/admin/common/upload/single`,
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
      Message.loading('上传中...', 0)
    }
    if (info.file.status === 'done') {


      // console.log(info.file.response)
      const { data } = info.file.response
      const { url } = data
      // console.log(url)
      cb(url, record)
      // onRefresh()
    } else if (info.file.status === 'error') {
      Message.destroy()
      Message.error(`${info.file.name} 上传失败`);
    }
  },
})

export default ({ onUpdate, onDel, onOperate, onRefresh, onRemoveFile }) => {
  const { role } = getStore('adminrole', 'session')
  return ([
    createColumn('检测ID', 'orderId', (t, { orderId }) => {
      return (
        <div style={{ cursor: 'pointer' }}>
          <Clipbord value={ orderId } completeCopy={ () => Message.success('复制成功') }>
            { orderId } <Icon type="copy" />
          </Clipbord>
        </div>
      )
    }, { width: '200px' } ),
    createColumn('检测需求', 'brief', (t, record) => {
      const { brief, cert, status, files = [], price } = record
      return (
        <div  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          { brief }
        </div>
      )
    }, { width: '200px' } ),
    createColumn('检测类型', 'category', null, { width: '200px' } ),
    createColumn('委托信息', 'weituo', (t, record) => {
      const { weituo } = record
      return (
        <span className='code-no'  onClick={ () => onUpdate(record, true) }
              style={{ cursor: 'pointer', color: '#555', fontSize: '12px', textDecoration: !!weituo ? 'underline' : 'none' }}>
          { !!weituo ? '查看委托单' : '-' }
        </span>
      )
    }),
    createColumn('订单金额', 'price', (t, { price, uploadedTicket, payWay }) => {
      return (
        <div>
          { !!price ? (`￥${ price }`) : '-' }

        </div>
      )
    }),
    createColumn('支付方式', 'payway', (t, { uploadedTicket, payWay }) => {
      return (
        <div>
          {
            !!!payWay && '-'
          }
          {payWay === 'wxpay' && '普通支付'}
          {
            payWay === 'tickets' && uploadedTicket && (
              <ViewTicket ticket={ uploadedTicket } />
            )
          }
        </div>
      )
    }),
    createColumn('检测报告', 'files', (t, record) => {
      const { files = [], orderId } = record
      return (
        <div>
          {
            files.length > 0 ? (
              <div style={{
                  marginTop: '6px',
                  // display: 'flex', flexWrap: 'wrap',
                  maxWidth: '150px',
                  // justifyContent: 'flex-start',
                  // alignItems: 'center'
                }}>
                {
                  files.map((o, i) => {
                    let idx = i + 1
                    const filename = o.split('/').pop().split('.')[0]
                    return (
                      <span key={ `${ orderId }-file-${i}` } className='code-no'
                          style={{  marginRight: '12px', fontSize: '10px', color: '#888',textDecoration: 'underline', display: 'block' }}>
                        <a href={ o } style={{color: '#888', marginRight: '6px'}} target='_blank'>
                          { filename.length <= 6 ? filename : `${filename.substr(0, 6)}...` }
                        </a>
                        {
                          role === 'admin' && (

                            <Popconfirm title="是否确认操作?" onConfirm={ () => onRemoveFile(record, i) } onCancel={ cancel } okText="是" cancelText="否">
                              <span style={{ cursor: 'pointer' }}><Icon type="close" /></span>
                            </Popconfirm>
                          )
                        }
                      </span>
                    )
                  })
                }
              </div>
            ) : '-'
          }
        </div>
      )
    }),
    createColumn('联系电话', 'phone'),
    createColumn('创建时间', 'createdTime', (t, { createdTime }) => {
      return moment('YYYY-MM-DD HH:mm')(createdTime)
    }, { width: '200px' } ),
    // createColumn('状态', 'status', (t, { status }) => {
    //   return status === 'Y' ? '启用' : '禁用'
    // }),
    createColumn('', 'action',(t, record) => (
        <span>
          {
            record.status == 1 ? (
              <Popconfirm title="是否确认操作?" onConfirm={ () => onOperate(record) } onCancel={ cancel } okText="是" cancelText="否">
                <a href="#" style={{ marginRight: '.12rem', fontSize: '.14rem' }}>确认需求</a>
              </Popconfirm>
            ) : record.status == 2
                ? [ //<InputPrice record={ record } _id={ record._id } onFetchSource={ onRefresh } />
                    (!!!record.payStatus || record.payStatus === 1 || record.payStatus === 4) ? (
                      <a href='#' style={{ marginRight: '.12rem', fontSize: '.14rem' }} onClick={ () => {
                          if (!!!record.weituo) {
                            const weituo = getStore('weituo', 'session')
                            if (!!weituo) {
                              onUpdate({...record, weituo})
                            } else {
                              onUpdate(record)
                            }
                          } else {
                            onUpdate(record)
                          }

                        } }>
                        {
                          !!!record.weituo ? '填写委托单' : '修改委托单'
                        }</a>
                    ) : (record.payStatus === 3 && record.payWay === 'tickets' && record.isConfirmTicket === 1) ? (
                      <ConfirmTickets _id={ record._id } onFetchSource={ onRefresh } />
                    ) : <div />

                ]
                : record.status == 3
                  ? (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Upload {...props(record, onRefresh, (url, record) => {
                        const { files = [] } = record
                        processRequest(
                          send('/api/admin/common/func', {
                            url: 'order/m/operate',
                            data: {
                              files: [...files, url],
                              id: record._id,
                              // status: 4,
                            }
                          }),
                          data => {
                            Message.destroy()
                            Message.success(`上传成功`)
                            // Message.success('操作成功！')
                            onRefresh()
                          }
                        )
                      })}>
                        <a>上传报告书</a>
                      </Upload>
                      <Popconfirm title="是否确认操作?" onConfirm={ () => onOperate(record) } onCancel={ cancel } okText="是" cancelText="否">
                        <a href="#" style={{ marginLeft: '.12rem', fontSize: '.14rem' }}>确认完成</a>
                      </Popconfirm>
                    </div>
                  )
                  : ''
          }
          {
            record.status === 3 && role === 'admin' && (
              <CheckRefund _id={ record._id } onFetchSource={ onRefresh }  />
            )
          }
          {
            (record.status == 1|| record.status == 2 || record.status == 4 || record.status == -1 ) && role === 'admin' && (
              <Popconfirm title="是否确认操作?" onConfirm={ () => onOperate(record, true) } onCancel={ cancel } okText="是" cancelText="否">
                <a href="#" style={{ marginRight: '.12rem', fontSize: '.14rem' }}>
                    { (record.status === 4 || record.status == -1) ? '删除订单' : '放弃检测' }
                </a>
              </Popconfirm>
            )
          }

        </span>
    ), {
      fixed: 'right'
    })
  ])
}
