import React from 'react'
import { Tag, Popconfirm, Message, Upload, Icon } from 'antd'
import { baseApiUrl } from 'config'
import { moment, send, getStore } from 'mulan-lib'
import { processRequest, createColumn } from 'lib'
import Clipbord from 'component/copyToClipbord'
import ConfirmRefund from './confirm-refund'
const cancel = (e) => console.log(e)

const props = (record, onRefresh, cb) => ({
  name: 'file',
  showUploadList: false,
  action: `${ baseApiUrl }/api/admin/common/upload/single`,
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
      Message.loading('上传中...', 0)
    }
    if (info.file.status === 'done') {


      // console.log(info.file.response)
      const { data } = info.file.response
      const { url } = data
      // console.log(url)
      cb(url, record)
      // onRefresh()
    } else if (info.file.status === 'error') {
      Message.destroy()
      Message.error(`${info.file.name} 上传失败`);
    }
  },
})

export default ({ onUpdate, onDel, onOperate, onRefresh, onRemoveFile }) => {
  const { role } = getStore('adminrole', 'session')
  return ([
    createColumn('检测ID', 'orderId', (t, { orderId }) => {
      return (
        <div style={{ cursor: 'pointer' }}>
          <Clipbord value={ orderId } completeCopy={ () => Message.success('复制成功') }>
            { orderId } <Icon type="copy" />
          </Clipbord>
        </div>
      )
    }),
    createColumn('检测需求', 'brief', (t, record) => {
      const { brief, cert, status, files = [], price } = record
      return (
        <div  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          { brief }
        </div>
      )
    }),
    createColumn('检测类型', 'category'),
    createColumn('委托信息', 'cert', (t, { cert }) => {
      return (
        <span className='code-no'
              style={{ cursor: 'pointer', fontSize: '12px', textDecoration: !!cert ? 'underline' : 'none' }}>
          { !!cert ? (<a href={ cert } style={{ color: '#555' }} target='_blank'>查看委托单</a>) : '-' }
        </span>
      )
    }),
    createColumn('订单金额', 'price', (t, { price, uploadedTicket, payWay }) => {
      return (
        <div>
          { !!price ? (`￥${ price }`) : '-' }

        </div>
      )
    }),
    createColumn('支付方式', 'payway', (t, { uploadedTicket, payWay }) => {
      return (
        <div>
          {
            !!!payWay && '-'
          }
          {payWay === 'wxpay' && '普通支付'}
          {payWay === 'tickets' && '对公转账'}
        </div>
      )
    }),
    createColumn('联系电话', 'phone'),
    createColumn('创建时间', 'createdTime', (t, { createdTime }) => {
      return moment('YYYY-MM-DD HH:mm')(createdTime)
    }),
    createColumn('操作时间', 'lastUpdateTime', (t, { lastUpdateTime }) => {
      return moment('YYYY-MM-DD HH:mm')(lastUpdateTime)
    }),
    createColumn('状态', 'refundStatus', (t, { refundStatus }) => {
      return refundStatus === 1 ? '待退款' : '已退款'
    }),
    createColumn('', 'action',(t, record) => (
      <span>
        {
          role === 'admin' && (
            record.refundStatus === 1 ? (
              [
                <ConfirmRefund _id={ record._id } record={ record } onFetchSource={ onRefresh }    />,
                <Popconfirm title="是否确认操作?" onConfirm={ () => onOperate(record, true) } onCancel={ cancel } okText="是" cancelText="否">
                  <a href="#" style={{ marginLeft: '.12rem', fontSize: '.14rem' }}>
                      放弃退款
                  </a>
                </Popconfirm>
              ]

            ) : record.refundStatus === 2 ? (
              <Popconfirm title="是否确认操作?" onConfirm={ () => onOperate(record, true) } onCancel={ cancel } okText="是" cancelText="否">
                <a href="#" style={{ marginRight: '.12rem', fontSize: '.14rem' }}>
                    删除订单
                </a>
              </Popconfirm>
            ) : (<a />)
          )

        }
      </span>
    ))
  ])
}
