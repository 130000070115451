import React from 'react'
import { Tag, Popconfirm,Select, Message, Popover, Row, Button, Col, Tooltip, InputNumber, Input } from 'antd'
import { getStore, get, send } from 'mulan-lib'
import { processRequest } from 'lib'

const Option = Select.Option
const cancel = (e) => console.log(e)
class App extends React.Component {
  constructor(props) {
    super(props)

    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleConfirm() {
    const { order, targetStatus } = this.props
    const { _id } = order
    const postData = {
      id: _id,
      // invoiceStatus: targetStatus,
    }

    if (targetStatus == -1) {
      postData.isInvoiceDel = true
    } else {
      postData.invoiceStatus = targetStatus
    }
    processRequest(
      send('/api/admin/common/func',
      {
        // adminKey,
        url: 'order/m/operate',
        data: postData
      }),
      data => {
        Message.success('修改成功')
        this.props.onFetchSource()
      }
    )
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps, prevState)
    return null
  }
  componentDidMount() {}
  render() {
    const { word } = this.props

    return (
      <Popconfirm title="是否确认操作?" onConfirm={ () => this.handleConfirm() } onCancel={ cancel } okText="是" cancelText="否">
        <a href="#" style={{ fontSize: '.14rem' }}>
          { word }
        </a>
      </Popconfirm>
      )
  }
}
export default App
