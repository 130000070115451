import React from 'react'
import { Tag, Popconfirm, Message, Popover, Button, Switch } from 'antd'
import { getStore, get, send } from 'mulan-lib'
import { processRequest } from 'lib'

class App extends React.Component {
  constructor(props) {
    super(props)

    const { _id: recordId } = props
    this.state = {
      recordId,
      isRefund: true
    }
    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleConfirm() {
    const { recordId, isRefund } = this.state

    const postData = {
      id: recordId,
      isRefund,
      status: -1,
    }
    if (isRefund) {
      postData.refundStatus = 1
    }
    processRequest(
      send('/api/admin/common/func',
      {
        url: 'order/m/operate',
        data: postData
      }),
      data => {
        Message.success('修改成功')
        this.setState({
          visible: false
        }, this.props.onFetchSource)
      }
    )
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps, prevState)
    return null
  }
  componentDidMount() {}
  handleVisibleChange(e) {
    console.log(e)
    this.setState({
      visible: e
    })

  }
  handleSwitchRefund(checked) {
    console.log(checked)
    this.setState({
      isRefund: checked
    })
  }
  handlHide() {
    this.setState({
      visible: false
    })
  }
  render() {
    return (
      <Popover
          trigger="click"
          visible={ this.state.visible }
          onVisibleChange={ this.handleVisibleChange }
          content={
            <div>
              <div style={{ margin: '.12rem 0' }}>
                是否退款：<Switch checkedChildren='是' unCheckedChildren='否' checked={ this.state.isRefund } onChange={ this.handleSwitchRefund.bind(this) } />
              </div>
              <div>
                <Button size='small' style={{ width: '100%' }} type='primary' onClick={ this.handleConfirm }>确认放弃</Button>
              </div>
              <div>
                <Button size='small' style={{ width: '100%', marginTop: '12px' }} onClick={ this.handlHide.bind(this) }>取消放弃</Button>
              </div>
            </div>
          }
          defaultVisible={ false }
        >
          <a href="#" style={{ marginRight: '.12rem', fontSize: '.14rem' }}>放弃检测</a>
        </Popover>
      )
  }
}
export default App
