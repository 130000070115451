import React from 'react'
import { Modal, Form, Input, Radio } from 'antd'
import { getStore, send } from 'mulan-lib'
import { processRequest } from 'lib'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  colon: true
};

class StoreForm extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}

    this.state = {
      adminKey
    }
  }
  componentWillUnmount() {
    const { form } = this.props
    form.resetFields()
  }

  handleCreate() {
    const { onCreate } = this.props
    const { adminKey } = this.state

    const form = this.props.form

    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      let path = '/api/admin/account/create'

      const { _id, ...params } = values

      const postData = {
        ...params,
        adminKey
      }
      if (_id) {
        postData._id = _id
        path = '/api/admin/account/operate'
      }
      processRequest(
        send(path, postData),
        data => {
          console.log(data)
          form.resetFields()
          onCreate()
        }
      )
    })
  }
  render() {
    const {
        visible, onCancel, onCreate, form,
        record = {}
    } = this.props
    console.log(record)
    const { _id = '' } = record

    const { getFieldDecorator } = form

    return (
      <Modal
          visible={ visible }
          title={ `${ _id ? '编辑' : '新增' }账号`  }
          okText= '确定'
          cancelText= '取消'
          onCancel={ onCancel }
          onOk={ this.handleCreate.bind(this) }
        >
          <Form layout="vertical">

          <Form.Item label="名称" { ...formItemLayout }>
            {getFieldDecorator('name', {
              initialValue: record.name || '',
              rules: [{ required: true, message: '请输入名称!' }],
            })(
              <Input placeholder='请输入名称' />
            )}
          </Form.Item>
            <Form.Item label="账号" { ...formItemLayout }>
              {getFieldDecorator('account', {
                initialValue: record.account || '',
                rules: [{ required: true, message: '请输入账号!' }],
              })(
                <Input placeholder='请输入账号' />
              )}
            </Form.Item>

            <Form.Item label="密码" { ...formItemLayout }>
              {getFieldDecorator('pwd', {
                initialValue: record.pwd || '',
                rules: [{ required: true, message: '请输入密码!' }],
              })(
                <Input placeholder='请输入密码' />
              )}
            </Form.Item>

            <Form.Item label="" { ...formItemLayout }>
              {getFieldDecorator('_id', {
                initialValue: record._id || '',
              })(
                <Input type='hidden' />
              )}
            </Form.Item>
          </Form>
        </Modal>
    )
  }
}

export default Form.create()(StoreForm)
