import React from "react";
import { Router, Route, browserHistory, IndexRoute } from "react-router";
import App from "./pages";
import Root from "./root";


import AdminLayout from "./admin/layout";
import Admin from "./admin";
import AdminLogin from "admin/login"
import AdminAccount from "admin/account"
import AdminOrder from "admin/order"
import AdminInvoice from "admin/invoice"
import AdminRefund from "admin/refund"

// 路由配置
const Routes = () => (
  <Router history={browserHistory}>
    <Route path="/" component={Root}>
      <IndexRoute component={App} />
    </Route>
    <Route path="/admin" component={AdminLayout}>
      <IndexRoute component={Admin} />
      <Route path="account/list" component={ AdminAccount } />
      <Route path="order/list/:status" component={ AdminOrder } />
      <Route path="invoice/:status" component={ AdminInvoice } />
      <Route path="refund/:status" component={ AdminRefund } />
    </Route>
    <Route path="/admin/login" component={ AdminLogin } />
  </Router>
);
export default Routes;
