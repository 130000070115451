import React from 'react'
import { browserHistory } from 'react-router'
import { getStore, get, setStore } from 'mulan-lib'
import { Layout, Message, Menu, Breadcrumb, Button, Icon, Dropdown } from 'antd'
import { Flex } from 'antd-mobile'
import './admin.css'
import createSubMenu from './create-submenu'
import menuList, { getCurrentBread } from './menu'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu


class App extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}
    const currentPub = getStore('currentPub', 'session')
    this.state = {
      adminKey,
      currentPub
    }
  }

  componentDidMount() {
  }
  render() {
    const adminInfo = getStore('admininfo', 'session') || {}
    const userInfo = getStore('userinfo', 'session') || {}
    const { orgType: adminType = '平台', roleName = '管理员' } = adminInfo
    const menu = (
      <Menu style={{ width: '150px', textAlign: 'center' }}>
        <Menu.Item key="1" onClick={ () => {
          window.sessionStorage.clear()
          window.location.href = '/admin/login'
        }}>
          退出登录
        </Menu.Item>
      </Menu>
    )
    const { publicSelector = '' } = this.state
    const { children, location } = this.props
    const { pathname } = location
    const defaultKey = pathname.lastIndexOf('/') === pathname.length - 1 ? pathname.substr(0, pathname.length - 1) : pathname
    const { breadName, pageName } = getCurrentBread(defaultKey)

    const submenu = createSubMenu(menuList, adminType, roleName)
//<img src={ require('img/logo_w.png') } style={{ height: 'calc(100% - .25rem)', maxWidth: '39px', marginRight: '14px', fontSize: '12px' }} />
    return (
      <Layout className="layout" style={{ backgroundColor: '#fff' }}>
          <Header theme='light' className="header">
            <Flex justify='between' align='center' style={{ height: '100%' }}>
              <Flex justify='start' align='center' style={{ height: '100%', color: '#fff' }}>
                摩检·食安管家
              </Flex>
              <Dropdown overlay={menu}>
                <a className="ant-dropdown-link" href="#" style={{ color: '#fff', fontSize: '.14rem' }}>
                  { userInfo.nickName || '管理员' } <Icon type="down" />
                </a>
              </Dropdown>
            </Flex>
          </Header>
          <Layout className="layout">
            <Sider theme='light'>
              <Menu
                theme="light"
                defaultSelectedKeys={[ defaultKey ]}
                style={{ lineHeight: '64px' }}
                mode="inline"
                inlineCollapsed={ false }
              >
                { submenu }
              </Menu>

            </Sider>
            <Layout style={{ position: 'relative' }}>
              <Content style={{ padding: '0 50px'}}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                  <Breadcrumb.Item>首页</Breadcrumb.Item>
                  <Breadcrumb.Item>{ breadName }</Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ background: '#fff', padding: '0 20px', minHeight: 280, overflow:'hidden', overflowY: 'scroll' }} >
                  <h1>{ pageName }</h1>
                  { children }
                </div>
              </Content>
            </Layout>
          </Layout>
        </Layout>
    )
  }
}

export default App
