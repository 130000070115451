import React from 'react'
import { Tag, Popconfirm,Select, Message, Popover, Row, Button, Col, Tooltip, InputNumber, Input } from 'antd'
import { getStore, get, send } from 'mulan-lib'
import { processRequest } from 'lib'

const cancel = (e) => console.log(e)

class App extends React.Component {
  constructor(props) {
    super(props)

    const { ticket } = props
    this.state = {
      ticket
    }
  }
  handleViewTicket() {
    const { ticket } = this.state
    processRequest(
      send('/api/admin/common/cloud/file',
      {
        // adminKey,
        url: ticket,
      }),
      data => {
        console.log(data)
        window.open(data)
      }
    )
  }
  componentDidMount() {}
  render() {
    const { inputNo = 0 } = this.state
    // const { contactName } = this.props
    return (
      <div>
        <div>对公转账</div>
      <span onClick={ this.handleViewTicket.bind(this) } className='code-no'
            style={{ cursor: 'pointer', fontSize: '12px', textDecoration:'underline', borderRadius: '30px' }}>
        查看汇款单
      </span>
      </div>
    )
  }
}
export default App
