import React from 'react'
import { Tag, Popconfirm,Select, Message, Popover, Row, Button, Col, Tooltip, InputNumber, Input } from 'antd'
import { getStore, get, send } from 'mulan-lib'
import { processRequest } from 'lib'

const Option = Select.Option
class App extends React.Component {
  constructor(props) {
    super(props)

    const { _id: recordId } = props
    this.state = {
      recordId
    }
    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleConfirm() {
    const { recordId, expressNo = '', expressCompany } = this.state
    if (!(expressNo && expressCompany)) {
      Message.warning('请输入有效信息')
      return
    }
    processRequest(
      send('/api/admin/common/func',
      {
        // adminKey,
        url: 'order/m/operate',
        data: {
          id: recordId,
          invoiceStatus: 3,
          expressNo,
          expressCompany,
        } }),
      data => {
        Message.success('修改成功')
        this.setState({
          visible: false
        }, this.props.onFetchSource)
      }
    )
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps, prevState)
    return null
  }
  componentDidMount() {}
  handleVisibleChange(e) {
    // console.log(e)
    if (e) {
      // this.fetchCategory()
    } else {
      this.setState({
        selectedCate: undefined,
        selectedType: undefined,
        selectedModelType: undefined,
        selectedBrand: undefined
      })
    }
    this.setState({
      visible: e
    })

  }
  render() {
    const { expressNo = '', expressCompany = '' } = this.state
    // const { contactName } = this.props
    return (
      <Popover
          title="填写物流信息"
          trigger="click"
          visible={ this.state.visible }
          onVisibleChange={ this.handleVisibleChange }
          content={
            <div>
              <div style={{ margin: '.12rem 0' }}>
                <Input placeholder='请输入运单号' value={ expressNo }
                onChange={ ({ currentTarget }) => this.setState({ expressNo: currentTarget.value }) } style={{ width: '150px', marginBottom: '.12rem' }} />
                <br/>
                <Input placeholder='请输入承运商' value={ expressCompany }
                onChange={ ({ currentTarget }) => this.setState({ expressCompany: currentTarget.value }) } style={{ width: '150px' }} />
              </div>
              <div>
                <Button style={{ width: '100%' }} type='primary' onClick={ this.handleConfirm }>确认</Button>
              </div>
            </div>
          }
          defaultVisible={ false }
        >
          <a style={{ fontSize: '.14rem', marginRight: '.12rem' }}>确认开票</a>
        </Popover>
      )
  }
}
export default App
