
import React from "react"

const detector = (UA) => ({

  isSafari: UA.indexOf("Safari") > -1 && UA.indexOf("Chrome") < 1,
  isIOS: UA.indexOf("iPhone") > -1 || UA.indexOf("iPad") > -1

})
export default (props) => {

  const clipboard = (value) => {

    const handleCopy =(e) => {
      e.clipboardData.setData('text/plain', value)
      e.preventDefault()

    }

    document.addEventListener("copy", handleCopy)
    document.execCommand('copy');
    document.removeEventListener('copy', handleCopy);

  }

  const inputCopy = (el, value) => {
    el.setAttribute("value", value)
    //el.style.visibility = "hidden"
    const ele = document.body.appendChild(el)
    const oldContentEditable = ele.contentEditable
    const oldReadOnly = ele.oldReadOnly

    const {isIOS, isSafari } = detector(window.navigator.userAgent)

    //fuck the fucking safari
    if(isIOS || isSafari ) {

      ele.contentEditable = true;
      ele.readOnly = true;

      const range = document.createRange();
      range.selectNodeContents(ele);

      const selection = window.getSelection();

      selection.removeAllRanges();
      selection.addRange(range);

      ele.setSelectionRange(0, 999999);
      ele.contentEditable = oldContentEditable;
      ele.readOnly = oldReadOnly;
    }else {
      ele.select()
    }
    document.execCommand("copy")
    document.body.removeChild(el)

  }

const handleClick = (e) => {
  const { value, completeCopy=()=>{} } = props
  const ele = document.createElement("input")
  const {isIOS, isSafari } = detector(window.navigator.userAgent)

  try {
    if(isIOS || isSafari ) throw Error() // safari 的复制特殊性
    clipboard(value)
  }catch(e){
    inputCopy(ele, value)
  }

  e.preventDefault()
  completeCopy()

}

  return (
    <div onClick={handleClick}>
      {props.children}
    </div>
  )
}
