import React from 'react'
import { Tag, Popconfirm, Message, Upload, Icon } from 'antd'
import { baseApiUrl } from 'config'
import { moment, send, getStore } from 'mulan-lib'
import { processRequest, createColumn } from 'lib'
import Clipbord from 'component/copyToClipbord'
import InputExpress from './input-express'
import StatusChange from './status-change'
const cancel = (e) => console.log(e)

const props = (record, onRefresh, cb) => ({
  name: 'file',
  showUploadList: false,
  action: `${ baseApiUrl }/api/admin/common/upload/single`,
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
      Message.loading('上传中...', 0)
    }
    if (info.file.status === 'done') {


      // console.log(info.file.response)
      const { data } = info.file.response
      const { url } = data
      // console.log(url)
      cb(url, record)
      // onRefresh()
    } else if (info.file.status === 'error') {
      Message.destroy()
      Message.error(`${info.file.name} 上传失败`);
    }
  },
})

const generateColumn = ({ onUpdate, onDel, onOperate, onRefresh, onRemoveFile }) => {
  const { role } = getStore('adminrole', 'session')
  const column =[
    createColumn('检测ID', 'orderId', (t, { orderId }) => {
      return (
        <div style={{ cursor: 'pointer' }}>
          <Clipbord value={ orderId } completeCopy={ () => Message.success('复制成功') }>
            { orderId } <Icon type="copy" />
          </Clipbord>
        </div>
      )
    }),
    createColumn('发票类型', 'invoiceType'),
    createColumn('开票信息', 'invoice', (t, record) => {
      const { invoice } = record

      const { title = '无', bankName = '无', bankAccount = '无',
        taxNumber = '无', telephone = '无',
        companyAddress = '无', type } = invoice

      // const { userName = '无', telNumber = '无', cityName = '无', detailInfo = '无', provinceName = '无', countyName = '无' } = receiver
      return (
        <Clipbord value={
            type == 0 ?
  `
  企业名称：${ title }
  企业税号：${ taxNumber }
  开户银行：${ bankName }
  银行账号：${ bankAccount }
  企业电话：${ telephone }
  企业地址：${ companyAddress }
  ` : `
  抬头：${ title }
  `
  } completeCopy={ () => Message.success('复制成功') }>
    {
      type == 0 ? ([
        <div>企业名称：{ title } <Icon type="copy" /></div>,
        <div>企业税号：{ taxNumber }</div>,
        <div>开户银行：{ bankName }</div>,
        <div>银行账号：{ bankAccount }</div>,
        <div>企业电话：{ telephone }</div>,
        <div>企业地址：{ companyAddress }</div>,
      ]) : (
        <div>个人抬头：{ title } <Icon type="copy" /></div>
      )
    }

        </Clipbord>
      )
    }),
    createColumn('收货信息', 'receiver', (t, record) => {
      const { receiver = {}, expressNo, expressCompany } = record
      const { userName = '无', telNumber = '无', cityName = '无', detailInfo = '无', provinceName = '无',
              countyName = '无' } = receiver
      return !(userName !== '无' && detailInfo !== '无') ? (
        '-'
      ) : (
        <Clipbord value={
  `
  收件人：${ userName }
  手机号：${ telNumber }
  所在区域：${ `${ provinceName } ${ cityName } ${ countyName }` }
  详细地址：${ detailInfo }
  `
  } completeCopy={ () => Message.success('复制成功') }>
          <div>收件人：{ userName } <Icon type="copy" /></div>
          <div>手机号：{ telNumber }</div>
          <div>所在区域：{ `${ provinceName } ${ cityName } ${ countyName }` }</div>
          <div>详细地址：{ detailInfo }</div>
        </Clipbord>
      )
    }),
    createColumn('金额', 'price', (t, record) => {
      return (
        `￥${ record.price }`
      )
    }),
    createColumn('物流信息', 'express', (t, record) => {
      const { expressCompany, expressNo } = record
      return (
        (expressCompany && expressNo) ? (
          <div>
            <div>承运商：{expressCompany}</div>
            <Clipbord value={expressNo} completeCopy={ () => Message.success('复制成功') }>
              <div>运单号：{expressNo} <Icon type="copy" /></div>
            </Clipbord>
          </div>
        ) : '-'
      )
    }),

  ]
  // console.log(window.location.pathname.indexOf('invoice/3'), window.location.pathname)
  // if (window.location.pathname.indexOf('invoice/3') >= 0) {
  //   column.push(
  //     createColumn('发货信息', 'price', (t, record) => {
  //       const { expressNo, expressCompany } = record
  //       return (
  //         <div>
  //         {
  //           expressNo && expressCompany && (
  //             <div>
  //               <div>承运商：{expressCompany}</div>
  //               <Clipbord value={expressNo} completeCopy={ () => Message.success('复制成功') }>
  //                 <div>运单号：{expressNo} <Icon type="copy" /></div>
  //               </Clipbord>
  //             </div>
  //           )
  //         }
  //         </div>
  //       )
  //     })
  //   )
  // }

  column.push(
    createColumn('', 'action',(t, record) => (
        <span>
          {
            record.invoiceStatus === 2 ? ([
              record.invoiceType.indexOf('纸质') >= 0 ? (
                <InputExpress _id={ record._id } onFetchSource={ onRefresh } />
              ) :
              (
                <Upload {...props(record, onRefresh, (url, record) => {
                    const { files = [] } = record
                    processRequest(
                      send('/api/admin/common/func', {
                        url: 'order/m/operate',
                        data: {
                          invoiceUrl: url,
                          id: record._id,
                          invoiceStatus: 3,
                          // status: 4,
                        }
                      }),
                      data => {
                        Message.destroy()
                        Message.success(`上传成功`)
                        // Message.success('操作成功！')
                        onRefresh()
                      }
                    )
                  })}><a style={{ marginRight: '.12rem' }}>上传电子发票</a></Upload>
              ),

              <StatusChange word='放弃开票' order={ record } targetStatus={4} onFetchSource={ onRefresh } />,
            ]) : (record.invoiceStatus === 3) ? (
              [
                (record.invoiceType.indexOf('纸质') < 0) && (
                  <a href={ record.invoiceUrl } style={{ fontSize: '.14rem', marginRight: '.12rem', }} target='_blank'>查看电子发票</a>
                ), (
                  role === 'admin' && (
                    <StatusChange word='删除' order={ record } targetStatus={-1} onFetchSource={ onRefresh } />
                  )
                )

              ]

            ) : (record.invoiceStatus === 4 && role === 'admin') ? (
              <StatusChange word='删除' order={ record } targetStatus={-1} onFetchSource={ onRefresh } />
            ) : false

          }
        </span>
    ))
  )

  return column
}

export default ({ onUpdate, onDel, onOperate, onRefresh, onRemoveFile }) => generateColumn({ onUpdate, onDel, onOperate, onRefresh, onRemoveFile })
