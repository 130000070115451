import React from 'react'
import { Tag, Popconfirm,Select, Message, Popover, Row, Button, Col, Tooltip, InputNumber, Input } from 'antd'
import { getStore, get, send } from 'mulan-lib'
import { processRequest } from 'lib'

// const cancel = (e) => console.log(e)

class App extends React.Component {
  constructor(props) {
    super(props)

    const { _id: recordId } = props
    this.state = {
      recordId
    }
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleConfirm() {
    const { recordId, inputNo = '' } = this.state

    processRequest(
      send('/api/admin/common/func',
      {
        // adminKey,
        url: 'order/m/operate',
        data: {
          id: recordId,
          isConfirmTicket: 2,
          payStatus: 2,
          status: 3,
        } }),
      data => {
        Message.success('确认成功')
        this.setState({
          visible: false
        }, this.props.onFetchSource)
      }
    )
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps, prevState)
    return null
  }
  handleCancel() {
    const { recordId, inputNo = '' } = this.state

    processRequest(
      send('/api/admin/common/func',
      {
        // adminKey,
        url: 'order/m/operate',
        data: {
          id: recordId,
          payStatus: 4,
          uploadedTicket: ''
        } }),
      data => {
        Message.success('确认成功')
        this.setState({
          visible: false
        }, this.props.onFetchSource)
      }
    )
  }
  componentDidMount() {}
  render() {
    const { inputNo = 0 } = this.state
    // const { contactName } = this.props
    return (
      <Popconfirm title="是否确认此操作?"
        onConfirm={ this.handleConfirm.bind(this) }
        onCancel={ this.handleCancel.bind(this) } okText="通过" cancelText="驳回">
        <a style={{ fontSize: '.14rem', marginLeft: '.12rem', marginRight: '.12rem' }}>确认电子回执</a>
      </Popconfirm>
    )
  }
}
export default App
